<template>
         <div class="title">
               <slot name="one" > </slot> 
      
        <!--  -->
        <slot name="two" > </slot> 
      </div>
</template>
<script>
export default {
    
     name: "Title",
  data() {
    return {};
  }
}
</script>
<style scoped>
.el-divider--horizontal {
  height: 3px;
}
.el-divider {
  background: linear-gradient(
    to right,
    #f4f4f7,
    #e1e4ea,
    #d7d9d9,
    #d7d9d9,
    #e1e4ea,
    #f4f4f7
  );
}
.el-divider__text {
  font-size: 32px;
}
.el-divider__text {
  background: #f4f4f7;
}
</style>
<style scoped lang="scss">
  .title {
    width: 880px;
    margin: 50px auto 0;
    display: grid;
    justify-items: center;
    .sub {
      text-align: center;
      display: block;
      color: #fff;
      font-size: 18px;
      background: #067fd8;
      padding: 2px 5px;
    }
  }
</style>