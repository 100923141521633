<template>
  <div class="top">
    <div>
      <button v-if="islist" @click="backHome"></button>
      <button v-else class="back" @click="back"></button>
    </div>
    <svg-icon icon-class="title" class-name="logo" />
  </div>
</template>
<script>
export default {
  name:'Top',
  props:['islist'],
  data(){
    return{

    }
  },
  methods:{
    backHome(){
      this.$router.push('/')
    },
    back(){
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
.top{
  height: 410px;
  position: relative;
  padding: 30px;
  background: url('../../../assets/img/bg.jpg') no-repeat;
  background-size: cover;
}
.top::after{
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  width: 750px;
  border-top-left-radius: 34px;
  border-top-right-radius: 34px;
  background-color: #fff;
  z-index: 2;
}
.top button{
  width: 80px;
  height: 56px;
  border-radius: 10px;
  padding:10px;
  background: url('../../../assets/img/icon-home.png') no-repeat center center;
  background-size: 36px;
  background-color: #fff;
}
.top button.back{
  width: 80px;
  height: 56px;
  border-radius: 10px;
  padding:10px;
  background: url('../../../assets/img/icon-back.png') no-repeat center center;
  background-size: 36px;
  background-color: #fff;
}
.logo{
  width: 690px;
  height: 36vw;
}
</style>