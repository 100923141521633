<template>
  <footer>吉ICP备07890000号-2</footer>
</template>
<script>
export default {
  name:'PageFooter'
}
</script>
<style scoped>
footer{
  background-color: #3f49c9;
  font-size: 20px;
  text-align: center;
  padding:30px;
  color: #fff;
  margin:24px 0px 0px 0px;
}
</style>